<template>
    <div class="notfound">
        <svg-icon name="404" />
        <div class="content">
            <h1>404</h1>
            <div class="desc">抱歉，你访问的页面不存在</div>
            <!-- <el-button type="primary" @click="goBack">{{countdown}}秒后，返回首页</el-button> -->
        </div>
    </div>
</template>

